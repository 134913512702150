<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 火车票订单
 * @Date: 2020-12-30 17:59:44
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-22 15:47:46
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/train.vue
-->
<template>
  <div class="user_content">
    <div v-if="!list.length">
      <van-empty
        image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
        description="暂无数据"
      />
    </div>
    <div class="orderlist">
      <ul />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty, Tab, Tabs } from 'vant'

Vue.use(Tab).use(Tabs).use(Empty)
import { getorderlist } from '@/services/comeOn.js'

export default {
  data() {
    return {
      mid: '',
      nolist: false,
      type: 0,
      page: 1,
      pageSize: 10,
      list: []
    }
  },
  created() {
    this.mid = localStorage.getItem('uid')
    this.getinfo()
  },
  methods: {
    getinfo() {
      const prom = {
        mid: this.mid,
        orderType: 601,
        status: 0,
        page: this.page,
        pageSize: this.pageSize
      }
      getorderlist(prom).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
        }
      })
    },
    tabkey() {
      this.getinfo()
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .orderlist {
    & > ul li {
      background: #FFFFFF;
      margin: 13px;
      padding: 10px;

      .sptitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span:nth-child(1) {
          color: #333333;
          font-size: 14px;
        }

        & > span:nth-child(2) {
          color: #999999;
          font-size: 13px;
        }
      }

      .spcenter {
        margin: 10px 0 0;

        & > p {
          color: #999999;
          font-size: 12px;
          text-align: left;
          line-height: 20px;
        }
      }

      .spcenter_text {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span:nth-child(1) {
          color: #999999;
          font-size: 12px;
        }

        & > span:nth-child(2) {
          color: #333333;
          font-size: 13px;
        }
      }

      .spfoot {
        border-top: 1px solid #F2F2F2;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;

        .s {
          border-radius: 15px;
          padding: 5px 10px;
          margin-right: 10px;
        }

        .st {
          color: #999999;
          border: 1px solid #999999;
        }

        .sc {
          color: #E43F44;
          border: 1px solid #E43F44;
        }
      }
    }
  }
}
</style>
